import React from 'react'
import Layout from '../components/layout'
import BlogHomepage from '../components/blog/homepage'
import Projects from '../components/projects'
import Intro from '../components/intro'

const Main = (props) => {
  return (
    <Layout>
      <Intro />
      {false && <BlogHomepage {...props} />}
      <Projects />
    </Layout>
  )
}

export default Main
