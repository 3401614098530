import React from 'react';

const Intro = () => {
  return (
    <div>
      <p>
        I'm Full Stack Developer at X-Team, an incredible community of remote
        work developers. I love working with clients around the world, using the
        power of javascript in all its forms(client, server, mobile).
      </p>
      <p>
        I've been in the technology scenery for more than 10 years, working as
        Lead Developer(Javascript, Python), Freelancer, Product Owner, Scrum
        Master, DBA(with Oracle, MySQL, Postgres, MongoDB, Google DataStore),
        Developer of Enterprise Applications (PL/SQL),
      </p>
      <p>
        I’m truly passionate about my work, learning about new technologies, and
        teamwork. I really enjoyed using Javascript as full stack language.
      </p>
      <p>
        I have a Master's Degree from UNITEC, a Computer Engineer Bachelor's
        Degree from the UNAH, and a Senior Web Developer Nanodegree from Udacity.
      </p>
    </div>
  )
}

export default Intro;