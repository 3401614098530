import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import { useGlobalSingleKeyShortcut } from '../hooks'

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const Half = styled.div`
  width: 50%;
  height: 100vh;
  transition: 3s ease-in-out;
  position: fixed;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-color: maroon;
  left: ${props => (props.left ? (props.hide ? '-100%' : 0) : 'auto')};
  right: ${props => (props.right ? (props.hide ? '-100%' : 0) : 'auto')};
  display: flex;
  justify-content: ${props => (props.left ? 'flex-end' : 'flex-start')};
  align-items: center;
`
const Sign = styled.div`
  border-top-left-radius: ${props => (props.left ? '8px' : 0)};
  border-bottom-left-radius: ${props => (props.left ? '8px' : 0)};
  border-top-right-radius: ${props => (props.right ? '8px' : 0)};
  border-bottom-right-radius: ${props => (props.right ? '8px' : 0)};
  border: solid black;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: ${props => (props.left ? '2px' : 0)};
  border-right-width: ${props => (props.right ? '2px' : 0)};
  background-color: #f58121;
  max-width: 200px;
  cursor: pointer;
  padding-left: ${props => (props.left ? '10px' : 0)};
  padding-right: ${props => (props.right ? '10px' : 0)};
  text-align: ${props => (props.left ? 'end' : 'start')};
`
const SignTitle = styled.h1`
  font-size: 2rem;
  font-weight: 800px;
  margin: 10px 0;
  padding: 5px 0;
  border: solid black;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: ${props => (props.left ? '4px' : 0)};
  border-right-width: ${props => (props.right ? '4px' : 0)};
  text-transform: uppercase;
`

const SignDescription = styled.div`
  padding: 10px 0;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
`

const SignSubText = styled.div`
  font-size: 1rem;
  padding: 10px 0;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`

const Graffitti = styled.div`
  font-size: 4rem;
  bottom: 30px;
  right: 20px;
  font-weight: 900;
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
  color: transparent;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  position: absolute;
  text-transform: uppercase;
`

const Sneak = ({ children }) => {
  const [sneak, sneaker] = useState()
  const changeSneak = (val = true) => {
    if (localStorage) {
      sneaker(val)
      localStorage.setItem('sneak', val)
    }
  }
  const peak = () => changeSneak(true)
  useGlobalSingleKeyShortcut('Enter', () => {
    peak()
  })
  useEffect(
    () => {
      if (localStorage) {
        sneaker(localStorage.getItem('sneak') === 'true')
      }
    },
    [sneak]
  )

  return (
    <Fragment>
      <Container hide={sneak}>
        <Half left hide={sneak}>
          <Sign onClick={peak} left>
            <SignTitle left>War</SignTitle>
            <SignDescription>Work In</SignDescription>
            <SignSubText>* Click here or hit En</SignSubText>
          </Sign>
        </Half>
        <Half right hide={sneak}>
          <Sign onClick={peak} right>
            <SignTitle right>ning</SignTitle>
            <SignDescription>&nbsp; Progress</SignDescription>
            <SignSubText>ter to get a sneak peak</SignSubText>
          </Sign>
          <Graffitti>jepz</Graffitti>
        </Half>
      </Container>
      {sneak && children}
    </Fragment>
  )
}

export default Sneak
