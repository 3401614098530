import React from 'react'

import Sneak from '../components/sneak'
import Main from '../components/main'

const IndexPage = props => {
  return (
    <Sneak>
      <Main />
    </Sneak>
  )
}

export default IndexPage
