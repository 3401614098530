import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  margin: 16px 0;
  font-size: 2.3rem;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  padding: 16px;
`

const Grid = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
`
const Item = styled.div`
  height: 100px;
  box-shadow: 1px 1px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom right, black, teal, black);
`

const BareLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 600;
  color: white;
  flex: 1;
`

const Project = ({ data }) => {
  return (
    <Item>
      <BareLink href={data.link} alt={data.title} target="_blank">
        {data.title}
      </BareLink>
    </Item>
  )
}
const Projects = props => {
  return (
    <div>
      <Title>Projects</Title>
      <Grid>
        <Project data={{link: 'https://coffeet.jepz.me', title: 'coffeeT'}}/>
        <Project data={{link: 'https://reviewer.jepz.me', title: 'reviewer'}}/>
      </Grid>
    </div>
  )
}

export default Projects
